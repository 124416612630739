<template>
  <div class="pa-5">
    <v-overlay v-if="loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-card
      class="mx-auto"
    >
      <v-card-title>
        <h2 v-t="'AccountsForm.EditingAccount'"></h2>
        <v-spacer></v-spacer>
        <v-btn color='secondary' @click="$router.push({ name: 'Accounts' })" v-t="'General.Return'"></v-btn>
      </v-card-title>
      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col
            >
              <v-text-field
                v-model="currentAccount.username"
                :label="$t('AccountsForm.AccountName')"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
            >
              <v-text-field
                v-model="password"
                :label="$t('AccountsForm.AccountPassword') + (($route.params.id.indexOf('new') === -1) ? $t('AccountsForm.LeaveEmpty') : '')"
                required
                type="password"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                outlined
                multiple
                chips
                deletable-chips
                v-model="currentAccount.roles"
                :items="['ROLE_USER', 'ROLE_ADMIN']"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-btn class="ma-5" color='primary' @click='addOrModifyAccount'>{{ $route.params.id.indexOf('new') === -1
        ? $t('General.Edit') : $t('General.Add') }}
      </v-btn>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'AccountsForm',
    data () {
      return {
        currentAccount: {
          id: '',
          name: '',
          roles: []
        },
        password: '',
        loading: false
      }
    },
    created () {
      if (this.$route.params.id.indexOf('new') === -1) {
        this.getCurrentAccount()
      }
    },
    methods: {
      getCurrentAccount () {
        this.$store.dispatch('getAccount', this.$route.params.id).then(() => {
          this.currentAccount = this.$store.getters['GET_CURRENT_ACCOUNT']()
          if (this.currentAccount === null) {
            this.$emit('notify', {
              color: 'red',
              text: this.$t('AccountsForm.AccountNotFound')
            })
            this.$router.push({ name: 'Accounts' })
          }
        })
      },
      addOrModifyAccount () {
        this.loading = true
        this.$store.dispatch('getAllAccounts').then(() => {
          var accounts = this.$store.getters['GET_ACCOUNTS']()
          if (!accounts.find((account) => { return account.username === this.currentAccount.username && account.id !== this.currentAccount.id })) {
            if (this.currentAccount.id) {
              this.modifyAccount()
            } else {
              this.addAccount()
            }
          } else {
            this.loading = false
            this.$emit('notify', {
              color: 'red',
              text: this.$t('AccountsForm.UsernameAlreadyUse')
            })
          }
        })
      },
      addAccount () {
        this.currentAccount.password = this.password
        this.$store.dispatch('addAccount', this.currentAccount).then(() => {
          this.$router.push({ name: 'Accounts' })
        }).catch((err) => {
          this.loading = false
          this.$emit('notify', {
            color: 'red',
            text: err.response.data
          })
        })
      },
      modifyAccount () {
        if (this.password) {
          this.currentAccount.password = this.password
        } else {
          this.currentAccount.password = ''
        }
        this.$store.dispatch('editAccount', this.currentAccount).then(() => {
          this.$router.push({ name: 'Accounts' })
        }).catch((err) => {
          this.loading = false
          this.$emit('notify', {
            color: 'red',
            text: err.response.data
          })
        })
      }
    }
  }
</script>

<style scoped>

</style>
